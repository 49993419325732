var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"mb-3 title",attrs:{"flat":"","show-expand":"","single-expand":_vm.singleExpand,"loader-height":"2","hide-default-footer":"","loading":_vm.processing,"headers":_vm.headers,"items":_vm.resources.data,"item-key":"resource_uid"},scopedSlots:_vm._u([{key:"item.value_chain_actions",fn:function({ item }){return [_c('v-btn',{staticClass:"caption ttn",attrs:{"dark":"","label":"","small":"","color":"primary"},on:{"click":() => {
          _vm.resourceUid = item.resource_uid
          _vm.value_chain_dialog = true
          }}},[_vm._v(" Add Value Chain ")])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"pa-5",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"title mb-3",attrs:{"flat":"","show-expand":"","single-expand":_vm.singleExpand,"center":"","fixed-header":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","loader-height":"1","headers":_vm.valuechainHeaders,"items":item.value_chains,"item-key":"value_chain_uid"},scopedSlots:_vm._u([{key:"item.output_action",fn:function({ item }){return [_c('v-btn',{staticClass:"caption ttn",attrs:{"dark":"","label":"","small":"","color":"primary"},on:{"click":() => {
                  _vm.valueChainUid = item.value_chain_uid
                  _vm.value_chain_output_dialog = true
                  }}},[_vm._v(" Add Value Chain Output ")])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"pa-5",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"title mb-3",attrs:{"flat":"","center":"","fixed-header":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","loader-height":"1","headers":_vm.valuechainOutputHeaders,"items":item.value_chain_outputs,"item-key":"value_chain_output_uid"}})],1)],1)],1)]}}],null,true)})],1)],1)],1)]}}])}),(_vm.value_chain_dialog)?_c('value-chain-form',{attrs:{"dialog":_vm.value_chain_dialog,"resource-uid":_vm.resourceUid},on:{"close":function($event){_vm.value_chain_dialog = false}}}):_vm._e(),(_vm.value_chain_output_dialog)?_c('value-chain-output-form',{attrs:{"dialog":_vm.value_chain_output_dialog,"value-chain-uid":_vm.valueChainUid},on:{"close":function($event){_vm.value_chain_output_dialog = false}}}):_vm._e(),(_vm.resources.meta)?_c('app-pagination',{attrs:{"meta":_vm.resources.meta},on:{"pageChanged":_vm.pageChanged}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }