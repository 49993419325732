<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        flat 
        show-expand
        :single-expand="singleExpand"
        class="mb-3 title" 
        loader-height="2"
        hide-default-footer
        :loading="processing"
        :headers="headers" 
        :items="resources.data"
        item-key="resource_uid"
      >

        <template v-slot:item.value_chain_actions="{ item }">
          <v-btn
            dark
            label
            small
            color="primary"
            class="caption ttn"
            @click="() => {
            resourceUid = item.resource_uid
            value_chain_dialog = true
            }"
          >
            Add Value Chain
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td 
            :colspan="headers.length"
            class="pa-5"
          >
            <v-card outlined>
              <v-card-text>
                <v-data-table
                  flat 
                  show-expand
                  :single-expand="singleExpand"
                  center
                  fixed-header
                  disable-sort
                  disable-pagination
                  hide-default-footer
                  class="title mb-3" 
                  loader-height="1"
                  :headers="valuechainHeaders" 
                  :items="item.value_chains" 
                  item-key="value_chain_uid"
                >
                <template v-slot:item.output_action="{ item }">
                  <v-btn
                    dark
                    label
                    small
                    color="primary"
                    class="caption ttn"
                    @click="() => {
                    valueChainUid = item.value_chain_uid
                    value_chain_output_dialog = true
                    }"
                  >
                    Add Value Chain Output
                  </v-btn>
                </template> 

                <template v-slot:expanded-item="{ headers, item }">
                <td 
                  :colspan="headers.length"
                  class="pa-5"
                >
                  <v-card outlined>
                    <v-card-text>
                      <v-data-table
                        flat 
                        center
                        fixed-header
                        disable-sort
                        disable-pagination
                        hide-default-footer
                        class="title mb-3" 
                        loader-height="1"
                        :headers="valuechainOutputHeaders" 
                        :items="item.value_chain_outputs" 
                        item-key="value_chain_output_uid"
                      >       
                    </v-data-table>
                    </v-card-text>
                  </v-card>
                </td>
              </template>      
              </v-data-table>
              </v-card-text>
            </v-card>
          </td>
        </template>

      </v-data-table>

      <value-chain-form
      v-if="value_chain_dialog"
        :dialog="value_chain_dialog"
        :resource-uid="resourceUid"
        @close="value_chain_dialog = false"
      />

      <value-chain-output-form
      v-if="value_chain_output_dialog"
        :dialog="value_chain_output_dialog"
        :value-chain-uid="valueChainUid"
        @close="value_chain_output_dialog = false"
      />

      <app-pagination
        v-if="resources.meta"
        :meta="resources.meta"
        @pageChanged="pageChanged"
      ></app-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    'value-chain-form': () => import('@/views/silo/ValueChainForm.vue'),
    'value-chain-output-form': () => import('@/views/silo/ValueChainOutputForm.vue'),
  },
  data () {
    return {
      page: 1,
      loading: true,
      value_chain_dialog: null,
      value_chain_output_dialog: null,
      singleExpand: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Actions', value: 'value_chain_actions' },
      ],
      valuechainHeaders: [
        { text: 'Value Chain Name', value: 'name' },
        { text: 'Action', value: 'output_action' },
      ],
      valuechainOutputHeaders: [
        { text: 'Value Chain Output Name', value: `name` },
      ],
    }
  },

  computed: {
    ...mapGetters({
      resources: 'getFarmResources',
    }),
  },

  methods: {
    ...mapActions([
      'setFarmResources',
    ]),

    pageChanged (page) {
      this.page = page
      this.setFarmResources()
    },
  },

  mounted () {
    this.setFarmResources()
      .then(() => this.loading = false)
  }
}
</script>
